@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: sajafFont;
  src: url("./fonts/sajaf-font.ttf");
}

/* @font-face {
  font-family: sajafFontBold;
  src: url("./fonts/sajaf-font-bold.ttf");
} */
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,600;9..40,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Vazirmatn:wght@300;400;600;700&display=swap");
body {
  margin: 0;
  font-family: sajafFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #e5e5e5;
}
::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 30px;
}
::-webkit-input-placeholder {
  font-size: 12px;
}
a,
a:active,
a:focus,
a:visited {
  color: inherit;
  text-decoration: none;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}

table tbody {
  direction: initial;
}

ol {
  list-style: decimal;
}
ul {
  list-style: disc;
}
